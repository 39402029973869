<template>
    <div class="main-box">
        <div class="pd-box">
            <div class="title">
                <p>序号：{{xuhao}}</p>
            </div>
            <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
                <div class="content">
                    <div class="con-item">
                        <a-form-item label="发票号码">
                            <a-input v-model:value="fapiaohaoma" placeholder="请输入发票号码"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="发票代码">
                            <a-input v-model:value="fapiaodaima" placeholder="请输入发票代码"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="发票日期">
                            <a-input v-model:value="fapiaoriqi" placeholder="请输入发票日期"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="销方名称">
                            <a-input v-model:value="xiaofangmingcheng" placeholder="请输入销方名称"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="金额">
                            <a-input v-model:value="jine" placeholder="请输入金额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="税额">
                            <a-input v-model:value="shuie" placeholder="请输入税额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="认证方式">
                            <a-input v-model:value="renzhengfangshi" placeholder="请输入认证方式"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="认证日期">
                            <a-input v-model:value="renzhengriqi" placeholder="请输入认证日期"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="发票类型">
                            <a-input v-model:value="fapiaoleixing" placeholder="请输入发票类型"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="发票状态">
                            <a-input v-model:value="fapiaozhuangtai" placeholder="请输入发票状态"></a-input>
                        </a-form-item>
                    </div>
                </div>
            </a-form>
        </div>
        <div class="con-box" style="text-align: center;">
            <a-button type="primary" style="margin-right: 20px;" @click="onSave">保存</a-button>
            <a-button type="primary" @click="$router.back()">取消</a-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'loginType',
            'isAuth',
            'orgId',
            'y'
        ])
    },
    data () {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            id: '',
            fapiaohaoma: '',
            fapiaodaima: '',
            xiaofangmingcheng: '',
            jine: '',
            shuie: '',
            renzhengfangshi: '',
            renzhengriqi: '',
            fapiaoleixing: '',
            fapiaozhuangtai: '',
            xuhao: '',
            fapiaoriqi: ''
        }
    },
    methods: {
        // 获取利润表
        getJXFPDetail () {
            this.$store.dispatch('getJXFPDetail', { JYDATA_JINXIANG_ID: this.id, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    let datas = res.pd
                    this.fapiaohaoma = datas.FAPIAO_NUMBER
                    this.fapiaodaima = datas.FAPIAO_CODE
                    this.fapiaoriqi = datas.FAPIAO_DATE
                    this.xiaofangmingcheng = datas.XIAOAGENT
                    this.jine = datas.XIAOMONEY
                    this.shuie = datas.SHUIMONEY
                    this.renzhengfangshi = datas.RENZHENGFANGSHI
                    this.renzhengriqi = datas.RENZHENGRIQI
                    this.fapiaoleixing = datas.FAPIAOTYPE
                    this.fapiaozhuangtai = datas.FAPIAOSTATUS
                } else {
                    this.$message.error('获取销项发票详情失败，请稍后重试！')
                }
            })
        },
        onSave () {
            this.$store.dispatch('editJXFPItem', {
                YEAR: this.y,
                JYDATA_JINXIANG_ID: this.id,
                ENTERPRISE_ID: this.orgId,
                FAPIAO_CODE: this.fapiaodaima,
                FAPIAO_NUMBER: this.fapiaohaoma,
                FAPIAO_DATE: this.fapiaoriqi,
                XIAOAGENT: this.xiaofangmingcheng,
                XIAOMONEY: this.jine,
                SHUIMONEY: this.shuie,
                RENZHENGFANGSHI: this.renzhengfangshi,
                RENZHENGRIQI: this.renzhengriqi,
                FAPIAOTYPE: this.fapiaoleixing,
                FAPIAOSTATUS: this.fapiaozhuangtai,
                tm: new Date().getTime()
            }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('修改成功！')
                    setTimeout(() => {
                        this.$router.back()
                    }, 1500)
                } else {
                    this.$message.error('保存失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.id = this.$route.query.id
        this.xuhao = this.$route.query.num
        this.getJXFPDetail()
    }
}
</script>
<style lang="less" scoped>
.ant-form ::v-deep(label){
    font-size: 12px;
}
::v-deep(.ant-form-item-label){
    overflow: visible;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
    }

    .pd-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                margin-bottom: 0;
            }
        }
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .con-item{
                width: 49%;
            }
        }
    }
}
</style>